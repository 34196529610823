<template>
  <div class="card-info card-mf-map">
    <div class="card-body-mf-map">
      <div class="row">
        <!-- <div class="col-5 col-md-4">
          <slot name="header">
            <div class="icon-big text-center"
                 :class="`text-${type}`">
              <i :class="icon"></i>
            </div>
          </slot>
        </div> -->
        <div class="col-5 col-md-6">
          <slot name="content">
            <div class="numbers">
              <p class="card-title">{{title}}</p>
              <p class="card-category">{{smallTitle}}</p>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'stats-card',
    props:{
      type: {
        type: String,
        description: 'Card text type (primary|info|danger|default|warning|success)'
      },
      title: {
        type: String,
        description: 'Card title'
      },
      smallTitle: {
        type: String,
        description: 'Card small title'
      },
      icon: {
        type: String,
        description: 'Card icon'
      }
    }
  }

</script>


<style>
.card-body-mf-map {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-mf-map{
  height: 135px !important;
  border-radius: 2px;
  margin-top: 10px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.5), 0 2px 5px 0 rgba(0,0,0,0.24);
  background-color: #f5f5f5;
  color: gray;
  margin-bottom: 20px;
  position: relative;
  border: 0 none;
}
  .card-title{
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 40px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color:darkgray;
  }
  .numbers{
    text-align: right;
  }
  .card-category {
    color: lightgray;
    font-size: 18px;
    /* line-height: 1.4em; */
  }
  p{
      margin-bottom: -15px;
  }
  /* .card-stats{

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 5px 5px 0;
      border: 0;
      .card-title{
        margin-top: 10px;
      }
    }
    .card-title{
      margin-top: 4px;
      padding-bottom: 5px;
      font-size: 0.9em;
    }
    .col{
      padding-right: 0px;
      padding-left: 0px;
    }
    .card-body{
        padding: 5px 5px 0px;
        min-height: 125px!important;
        background-color: white;

        .numbers{
          text-align: right;
          font-size: 2em;

            p{
                margin-bottom: 0;
            }
            .card-category {
              color: lightgray;
              font-size: 12px;
              line-height: 1.4em;
            }
        }
    }
    .icon-big {
        font-size: 3em;
        min-height: 64px;

        i{
            line-height: 59px;
        }
    }
  } */



</style>

