import Home from '@/views/Home/Index.vue'
////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/////////
import { authService } from "@ingeo-mf/styleguide";
//////////

const routes = [
  {
    path: '/map',
    name: 'Recorridos',
    component: Home,
    meta: {
        public: false,
        onlyWhenLoggedOut: false
    },
    beforeEnter(to, from, next) {
      if (authService.hasPermission(to)) {
        next()
      }
    }
  }
];

export default routes
