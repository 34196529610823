<template>
  <div id="map">
    <GmapMap
      :center="center"
      :options="mapOptions"
      @maptypeid_changed="mapTypeIdChanged"
      style="width: 100%; height: 100%"
      ref="map"
    >
      <GmapCluster :zoomOnClick="true" :minimumClusterSize="4">
        <GmapMarker
          v-for="m in markers"
          :key="m.title"
          :position="m.position"
          :animation="m.animation"
          :icon="m.icon"
          :title="m.title"
          :shape="m.shape"
          :clickable="true"
          :draggable="false"
          @mouseover="openInfoWindow(m)"
          @mouseout="closeInfoWindow()"
          @click="clickOnMarker(m)"
        ></GmapMarker>
      </GmapCluster>

      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWinPos"
        :opened="infoWinOpen"
        @closeclick="closeInfoWindow()"
      ></GmapInfoWindow>

      <GmapPolyline
        v-show="routePath.length > 0"
        :path="routePath"
        :options="pathOptions"
        :editable="false"
        ref="polyline"
      ></GmapPolyline>

      <!-- <div slot="visible">
        <div style="bottom: 0; right: 0;  text-align: right; width: 100%; font-weight: bolder; background-color: #005ec0; color: white; position: absolute; z-index: 100">
          {{mapStatusText}}
        </div>
      </div> -->

    </GmapMap>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import {EventBus} from '@/eventBus'
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import GmapMarker from "vue2-google-maps/dist/components/marker";
import GmapMap from "vue2-google-maps/dist/components/map";
import GmapPolyline from "vue2-google-maps/dist/components/polyline";
import GmapInfoWindow from "vue2-google-maps/dist/components/infoWindow";
import swal from 'sweetalert2'
/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import vehiculosService from "../../../../../ingeo-mf-styleguide/src/services/vehiculos"
/// #else
import { vehiculosService } from "@ingeo-mf/styleguide";
/// #endif

export default {
  components: {
    GmapCluster,
    GmapMarker,
    GmapMap,
    GmapPolyline,
    GmapInfoWindow
  },
  data() {
    return {
      map: {},
      mapStatusText: '',
      googleMapsInitialized: false,
      mapChartPointer: {},
      routePath: [],
      routeBounds: [],
      firstPath: false,
      markersArray: [],
      markers: [],
      center: { lat: -33.748817, lng: -60.985428 },
      pathOptions: {
          geodesic: false,
          strokeColor: "#005ec0",
          strokeOpacity: 0.8,
          strokeWeight: 6
      },
      mapOptions: {
        zoom: 8,
        scrollwheel: true,
        mapTypeId: "terrain",
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        mapTypeControlOptions: {
          style: "",
          position: ""
        }
      },
      platform: 'Desktop',
        infoWinPos: null,
        infoWinOpen: false,

        infoOptions: {
        content: '',
        pixelOffset: {
            width: 0,
            height: -35
        }
        },
        mapUpdateTimer: {},
        vehWithNoData: []
    };
  },

  watch: {
    patenteSelected() {
      this.loadRoute(this.patenteSelected)
    }
  },

  computed: {
    ...mapState(["patenteSelected", "positionQuery", "grupos", "route", "redisRoute"]),
    google: gmapApi
  },

  created() {
    if (window.innerWidth <= 768) {
      this.platform = 'Mobile'
    } else {
      this.platform = 'Desktop'
    }
  },

  mounted() {

    this.$refs.map.$mapPromise
      .then(map => {
        this.map = map;
        this.mapInitialize();
        this.googleMapsInitialized = true;
        this.queryToUpdateDataMap()
        this.startPeriodicMapUpdate()
      })
      .catch(error => this.mapLoadError(error));


      EventBus.$on('map-pan-to-pin', data => {
        this.mapPanTo(data.vehId)
      })

      EventBus.$on('map-change-range', data => {
        let minutes = 60 * parseInt(data.trim().substring(0,2));
        minutes += parseInt(data.trim().substring(3,4));
        this.$store.commit("setMinutesBackward", minutes);
        this.updateRoute();
      })

  },

    beforeDestroy () {
        clearInterval(this.mapUpdateTimer)
    },

  methods: {

    mapPanTo(vehId) {
     var markerVeh = this.markers.filter(i => i.title === vehId)[0]
     this.map.panTo(markerVeh.position)
    //  this.animateSelectedPin(markerVeh)
     this.openInfoWindow(markerVeh);
     if (this.platform == 'Mobile') {
        this.map.setZoom(16)
     } else {
      if (this.routeBounds) {
        // this.map.panTo(this.route[0])
        this.map.fitBounds(this.routeBounds)
      }
     }
    },

    loadRoute(pat) {
      this.getRoute(pat)
    },

    getRoute(pat) {
      if (!pat) return;
      let limit = 0; //this.getBackwardInPoints();
      vehiculosService.route(pat, limit).then(res => {
        if(res.data){
          this.$store.commit("setRedisRoute", { rutaVehiculo: res.data });
          // this.$store.commit("setRoute", { rutaVehiculo: res.data });
          this.updateRoute()
        }else{
          swal({
            html: "Vehiculo " + pat + " sin posicion",
            buttonsStyling: false,
            confirmButtonClass: 'swal2-cancel btn btn-danger',
            type: 'error'
          })
        }
      });

      vehiculosService.vbat(pat).then(res => {
        if (Array.isArray(res.data) && res.data.length) {
            this.$store.commit("setVbat", res.data[0].log_chan_last_val);
        }
      });
    },

    getBackwardInPoints() {
        if (this.positionQuery.unityForBackward == 'time'){
            return this.positionQuery.minutesBackward*2;
        }
        else if (this.positionQuery.unityForBackward == 'distance') {
            return this.positionQuery.metersBackward/100;
        }
        return 1
    },

    startPeriodicMapUpdate () {
        var vm=this
        this.mapUpdateTimer = setInterval(function () {
            vm.queryToUpdateDataMap()
        }, 30000)
    },

    queryToUpdateDataMap() {
        vehiculosService.index().then(res => {
            this.$store.commit("setGrupos", { gruposVehiculos: res.data });
            this.updatePositions();
        })
        if (this.patenteSelected) {
            this.getRoute(this.patenteSelected)
        }
    },

    openInfoWindow(marker) {
        this.infoWinPos = marker.position;
        this.infoOptions.content = this.formatMapTooltip(marker)
        this.infoWinOpen = true;
    },

    closeInfoWindow() {
        this.infoOptions.content = '';
        this.infoWinOpen = false;
    },

    clickOnMarker(markerVeh) {
      if (this.platform == 'Mobile') {
        this.openInfoWindow(markerVeh)
      }
      this.animateSelectedPin(markerVeh)
      this.$store.commit("selectPatente", {
        patenteVehiculo: markerVeh.title
      });
    },

    animateSelectedPin(markerVeh) {
      markerVeh.animation = this.google.maps.Animation.BOUNCE;
      setTimeout(function() {
        markerVeh.animation = null;
      }, 900);
    },

    updateRoute() {
      this.$store.commit("setRoute", { rutaVehiculo: this.redisRoute });
      this.routeBounds = new this.google.maps.LatLngBounds()
      this.routePath.splice(0);

      this.route.forEach(routeItem => {
        let routePoint = new this.google.maps.LatLng(routeItem.lat,routeItem.lng)
        this.routePath.push(routePoint)
        this.routeBounds.extend(routePoint)
      })

      var distance = this.google.maps.geometry.spherical.computeLength(this.routePath);
      this.$store.commit("setDistance", distance);
      this.markers
          .filter(i => i.title === this.patenteSelected)[0]
          .position = this.routePath[0];
          // .position = this.routePath[this.routePath.length-1];

    },

    updatePositions() {
      var iconSize = 48;
      var markerImage = {
        url: require("@/assets/img/icons/icon-map-run-48.png"),
        size: new this.google.maps.Size(iconSize, iconSize),
        origin: new this.google.maps.Point(0, 0),
        anchor: new this.google.maps.Point(iconSize / 2, iconSize - 10)
      };
      this.grupos.forEach(grupoItem => {
        grupoItem.grupos_vehiculos.forEach(vehItem => {

          if (vehItem.pos) {
            var marker = {
                position: new this.google.maps.LatLng(
                    vehItem.pos.lat,
                    vehItem.pos.lng
                ),
                animation: this.google.maps.Animation.DROP,
                title: vehItem.veh_id,
                veh: vehItem.veh_id,
                vel: vehItem.pos.log_gps_velocidad,
                timegps: vehItem.pos.log_gps_timegps,
                group: grupoItem.grupo_nombre,
                // text: `Vehículo: ${vehItem.veh_id} <br>
                //         Grupo: ${grupoItem.grupo_nombre} <br>
                //         Velocidad: ${vehItem.pos.log_gps_velocidad} Km/h<br>
                //         Ultimo Registro: ${vehItem.pos.log_gps_timegps}`,
                icon: {
                    url: vehItem.pos.log_gps_velocidad
                    ? require(`@/assets/img/icons/icon-map-run-${iconSize}.png`)
                    : require(`@/assets/img/icons/icon-map-stop-${iconSize}.png`),
                    size: markerImage.size,
                    origin: markerImage.origin,
                    anchor: markerImage.anchor
                },
                shape: {
                    coords: [1, 1, 1, 28, 28, 28, 28, 1],
                    type: "poly"
                }
            }

            if ( this.markers.filter(i => i.title === vehItem.veh_id).length) {

                this.markers.forEach(item => {
                    if (item.title === vehItem.veh_id) {
                        item.position = marker.position
                        item.text = marker.text
                        item.icon = marker.icon
                    }
                })
            }
            else {
              this.markers.push(marker);
            }
          }
          else {
            // console.log("Sin posición: ", vehItem.veh_id);
            if ( !this.vehWithNoData.find(i => i === vehItem.veh_id)) {
                this.vehWithNoData.push(vehItem.veh_id)
            }
          }
        });
      });
    },

    mapInitialize() {
      if (this.platform == 'Desktop') {
        this.mapOptions.zoomControl = true;
      } else {
        this.mapOptions.zoomControl = false;
      }
      this.mapOptions.mapTypeControlOptions.style = this.google.maps.MapTypeControlStyle.HORIZONTAL_BAR;
      this.mapOptions.mapTypeControlOptions.position = this.google.maps.ControlPosition.TOP_RIGHT;

      this.$store.commit("setMap", {
        mapa: this.map
      });
      this.mapChartPointer = this.mapInitChartPointer("terrain");
    },

    // Inicializa el puntero del mapa que marca la posición relacionada con mouseover del gráfico (de velocidad)
    mapInitChartPointer(mapTypeId) {
      var mapChartPointer;
      if (mapTypeId === "hybrid" || mapTypeId === "satellite") {
        mapChartPointer = new this.google.maps.Marker({
          icon: {
            path: this.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: "black",
            fillOpacity: 1,
            strokeWeight: 5,
            strokeColor: "white",
            strokeOpacity: 0.8
          }
        });
      } else {
        mapChartPointer = new this.google.maps.Marker({
          icon: {
            path: this.google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: "black",
            fillOpacity: 1,
            strokeWeight: 25,
            strokeColor: "gray",
            strokeOpacity: 0.5
          }
        });
      }
      // TEST:
      // let logLatlng = new this.google.maps.LatLng(0, 0);
      // mapChartPointer.setPosition(logLatlng);
      // mapChartPointer.setMap(this.map)

      // TODO: Cambiar nombre marker del store, por otro más representativo
      this.$store.commit("setMarker", {
        marker: mapChartPointer
      });
      return mapChartPointer;
    },

    mapTypeIdChanged(type) {
      if (this.googleMapsInitialized)
        this.mapChartPointer = this.mapInitChartPointer(type);
    },

    mapLoadError(error) {
      alert("No se pudo inicializar el mapa:", error);
    },

    formatMapTooltip (marker) {

        var html= "<div><table border=0>"
        html += "<tr><td rowspan=2 align=left>";

        let iconState = marker.vel?'icon-state-vel-a.png':'icon-state-stop-a.png'
        let icon = require(`@/assets/img/icons/state/${iconState}`)
        html += `<img height='35' style='margin-top: -20px;' src=${icon} />`

        html += `</td><td align=right style='font-weight:bolder; font-size:28px;'><strong>${marker.veh}</strong></td></tr>`

        let velText = marker.vel?`${marker.vel} km/h`:'Vehículo Detenido'
        html += `<tr><td align=right style='font-weight:bolder;'><strong>${velText}</strong></td></tr>`

        html += "<tr><td align=left>Fecha:</td><td align=right>"
             + marker.timegps.toString().substring(8,10) + "-"
             + marker.timegps.toString().substring(5,7) + "-"
             + marker.timegps.toString().substring(0,4) + "</td></tr>"
             + "<tr><td align=left>Hora :</td><td align=right>" + marker.timegps.toString().substring(11,20) +  "</td></tr>"
             + "<tr><td align=left>Grupo :</td><td align=right>" + marker.group +  "</td></tr>";

        return html;
     }

  }
};
</script>

<style scoped>
  #map {
    transition: margin-left 0.5s;
    object-fit: cover;
    width: 100%;
    padding-top: 60px;
    height: calc(100% - 155px); /* Footer height */
  }
</style>
