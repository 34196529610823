import Vue from "vue";
import Router from "vue-router";
import routes from './routes'
////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////////
import { TokenService } from "@ingeo-mf/styleguide";
//////////

Vue.use(Router);
const router = new Router({
    routes,
    mode: 'history',
    linkActiveClass: 'active',
  })

export default router;
