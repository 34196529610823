<template>
  <div class="row map-foot-bar">
      <info-card type="info"
        :title="groupCard.title" :small-title="groupCard.smallTitle"
        icon="nc-icon nc-alert-circle-i">
       </info-card>
      <info-card type="info"
        :title="infoCard.title" :small-title="infoCard.smallTitle"
        icon="nc-icon nc-alert-circle-i">
       </info-card>
      <info-card type="info"
        :title="routeCard.title" :small-title="routeCard.smallTitle"
        icon="nc-icon nc-sound-wave">
      </info-card>
      <info-card type="info"
        :title="vmaxCard.title" :small-title="vmaxCard.smallTitle"
        icon="nc-icon nc-settings">
      </info-card>
      <info-card type="info"
        :title="stateCard.title" :small-title="stateCard.smallTitle"
        icon="nc-icon nc-settings">
      </info-card>
      <info-card type="info"
        :title="ageCard.title" :small-title="ageCard.smallTitle"
        icon="nc-icon nc-settings">
      </info-card>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import { mapState } from "vuex";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
// import { Card, StatsCard }  from "../../../../../ingeo-mf-styleguide/src/UIComponents"
/// #else
// import { Card, StatsCard }  from "@ingeo-mf/styleguide";
/// #endif

import InfoCard from '../../../components/Card/InfoCard'

export default {
  components: {
    InfoCard
  },
  data() {
    return {
        footerHeight: Number,
        groupCard: {
            smallTitle:'Grupo',
            title:'',
        },
        infoCard: {
            smallTitle:'Vehículo',
            title:'',
        },
        routeCard: {
            smallTitle:'Distancia',
            title:'',
        },
        vmaxCard: {
            smallTitle:'Velocidad Máxima',
            title:'',
        },
        stateCard: {
            smallTitle:'Batería',
            title:'',
        },
        ageCard: {
            smallTitle:'Última actualización',
            title:'',
        }
    };
  },
  watch: {
    patenteSelected() {
        this.infoCard.title = this.patenteSelected
        this.groupCard.title = this.getGrupoFromVeh(this.patenteSelected).substring(0, 15);
        if (!this.patenteSelected) {
          this.cleanCardsContent();
        }
    },
    distance() {
        this.routeCard.title = (this.positionLastInfo.distance/1000).toFixed(1) + " Km"
    },
    vmax() {
        this.vmaxCard.title = this.positionLastInfo.vmax + " Km/h"
    },
    ageGps() {
        this.ageCard.title = this.secondsToTime(this.positionLastInfo.positionAge)
    },
    vbat() {
        this.stateCard.title = this.positionLastInfo.vbat + " V"
    }
  },
  computed: {
    ...mapState(["patenteSelected", "positionLastInfo","grupos", "routeChart", "map", "marker"]),
    vbat() {
      return this.positionLastInfo.vbat
    },
    distance() {
      return this.positionLastInfo.distance
    },
    vmax() {
      return this.positionLastInfo.vmax
    },
    ageGps() {
      return this.positionLastInfo.positionAge
    },

  },
  mounted() {
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
    if (this.patenteSelected) {
      this.updateCardsContent();
    }
  },

  methods: {
    updateCardsContent() {
      this.infoCard.title = this.patenteSelected
      this.groupCard.title = this.getGrupoFromVeh(this.patenteSelected).substring(0, 15);
      this.routeCard.title = (this.positionLastInfo.distance/1000).toFixed(1) + " Km"
      this.vmaxCard.title = this.positionLastInfo.vmax + " Km/h"
      this.ageCard.title = this.secondsToTime(this.positionLastInfo.positionAge)
      this.stateCard.title = this.positionLastInfo.vbat + " V"
    },
    cleanCardsContent() {
      this.routeCard.title = '';
      this.vmaxCard.title = '';
      this.ageCard.title = '';
      this.stateCard.title = '';
    },
    secondsToTime(secValue) {

        var Days = Math.floor(secValue/60/60/24);
        var remDays = (secValue/60/60/24) - Math.floor(secValue/60/60/24);
        var Hours = Math.floor(remDays*24);
        var remHours = (remDays*24) - Math.floor(remDays*24);
        var Minutes = Math.floor(remHours*60);

        if (!Minutes)
            var Seconds = Math.floor(remHours*60*60);

        if( Days>0)
            return  Days+" D "+Hours+" hr " //+Minutes+" '"

        if (Hours >0)
            return Hours+" hr "+Minutes+" '"

        if (Minutes >0)
            return Minutes+" min"

        return Seconds+" seg"

    },
    getGrupoFromVeh(pat) {
      var gr;
      var ve;
      for (gr = 0; gr < this.grupos.length; gr++) {
        for (ve = 0; ve < this.grupos[gr].grupos_vehiculos.length; ve++) {
            if (pat == this.grupos[gr].grupos_vehiculos[ve].veh_id) {
                return this.grupos[gr].grupo_nombre
            }
        }
      }
      return ''
    },
    // handleResize() {
      // let windowWidth = window.innerWidth;
      // this.footerHeight = document.getElementById("map-footer").offsetHeight;
      // let chartWidth = windowWidth - 760;
      // document.getElementById("speedChart").style.width = chartWidth + "px";
    // },

  }
};
</script>

<style >
.map-foot-bar {
    background-color: #f5f5f5;
    bottom: -164px;
    height: 164px;
    overflow: hidden;
    position: fixed;
    white-space: nowrap;
    width: 100%;
    -webkit-transition: bottom .218s cubic-bezier(0.4,0.0,0.2,1);
    -moz-transition: bottom .218s cubic-bezier(0.4,0.0,0.2,1);
    -o-transition: bottom .218s cubic-bezier(0.4,0.0,0.2,1);
    transition: bottom .218s cubic-bezier(0.4,0.0,0.2,1);
    box-shadow: 0 5px 26px rgba(0,0,0,.22), 0 20px 28px rgba(0,0,0,.30);
}

.row {
  margin: 0px;
  -ms-flex: auto;
  flex: auto;
  padding: 0 10px 0 0;
}

</style>
