import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        grupos: [],
        map: {},
        marker: {},
        redisRoute: [],
        route: [],
        routeChart: [],
        patenteSelected: '',
        dateFrom: '',
        dateTo: '',
        positionQuery: {
            minutesBackward: 90,
            metersBackward: 10000,
            unityForBackward: 'time'
        },
        settings: {
            sidebarMini: Boolean
        },
        positionLastInfo:{
            distance:'',
            vmax:'',
            positionAge:'',
            vbat:''
        },
        vehiculosSeleccionados: [],
        reporte: {},
        detalleReporte: {
          patente: '',
          idReporte: '',
          fechaDesde: '',
          fechaHasta: '',
          vehiculos: '',
          tableData: []
        },
        historicoMapa: {}
    },

    mutations: {

        setVbat(state, payload) {
            state.positionLastInfo.vbat = payload
        },

        setMinutesBackward(state, payload) {
            state.positionQuery.minutesBackward = payload
        },

        setMetersBackward(state, payload) {
            state.positionQuery.metersBackward = payload
        },

        selectPatente(state, payload) {
            state.patenteSelected = payload.patenteVehiculo
        },

        setGrupos(state, payload) {
            state.grupos = payload.gruposVehiculos
        },

        setMap(state, payload){
            state.map = payload.mapa
        },

        setMarker(state, payload) {
            state.marker = payload.marker
        },

        setDistance(state, payload) {
            state.positionLastInfo.distance = payload
        },

        setRedisRoute(state, payload) {
          state.redisRoute = payload.rutaVehiculo;
        },

        setRoute(state, payload) {

            let oldestTime = new Date()
            oldestTime.setMinutes(oldestTime.getMinutes() - state.positionQuery.minutesBackward)

            let validRoute = []
            let validRouteChart = []
            let vmax = 0;
            let nowDate = new Date()
            let newestAgeSeconds = ( new Date().getTime() -
                                     new Date(payload.rutaVehiculo[payload.rutaVehiculo.length-1].log_gps_timegps).getTime() ) / 1000;

            for (let i=payload.rutaVehiculo.length-1 ; i>=0 ; i--) {
                let value = payload.rutaVehiculo[i]
                let timeGps = new Date(value.log_gps_timegps)
                if ( timeGps.getTime() >= oldestTime.getTime()) {
                    let obj = {
                      x: timeGps.getTime(),
                      y: value.vel,
                      lat: value.lat,
                      lng: value.lng
                    }
                    if (value.vel > vmax) vmax = value.vel

                    validRoute.push(value)
                    validRouteChart.push(obj)
                }
            }

            if (validRoute.length) {
                state.route = validRoute
            }
            else {
                state.route.length = 0;
                state.route.push(payload.rutaVehiculo[0])
            }
            // state.routeChart.slice(0)
            state.routeChart = validRouteChart
            state.positionLastInfo.vmax = vmax

            if (newestAgeSeconds < 0 ) newestAgeSeconds = 30
            state.positionLastInfo.positionAge = newestAgeSeconds
        },

        setReporte(state, payload) {
            state.dateFrom = payload.dateFrom
            state.dateTo = payload.dateTo
            state.vehiculosSeleccionados = payload.vehiculosSeleccionados
        },

        setDetalleReporte(state,payload){
          state.detalleReporte.idReporte = payload.idReporte
          state.detalleReporte.patente = payload.patente
          state.detalleReporte.fechaDesde = payload.fechaDesde
          state.detalleReporte.fechaHasta = payload.fechaHasta
          state.detalleReporte.tableData = payload.tableData
          state.detalleReporte.vehiculos = payload.vehiculos
        },

        setSettings(state, payload) {
            state.settings.sidebarMini = payload.sidebarMini
        },

        setStorymap(state, payload) {
            state.historicoMapa = payload.fila
        }
    },

    getters: {
        getSidebarMini: state => {
          return state.settings.sidebarMini
        }
    },

    actions: {
        selectPatente(state, data) {
            return new Promise((resolve, reject) => {
                state.patenteSelected = data
                resolve;  // Let the calling function know that http is done. You may send some data back
            })
        }
    }
})

export default store
