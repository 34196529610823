<template>
    <div class="container-mf-map">
      <dashboard-layout></dashboard-layout>
    </div>
</template>

<script>
    import DashboardLayout from '@/layout/DashboardLayout.vue'
    export default {
        name: "App",
        props: {
            token: String,
        },
        components: {
            DashboardLayout,
        },
    }
</script>

<style>
#app {
    position: absolute;
}
</style>


