<template>
  <div>
    <div class="main-panel">
      <dashboard-content id="content">
      </dashboard-content>
    </div>
  </div>
</template>

<script>
  import DashboardContent from './Content.vue'
  // import {SettingsService} from '../services/storage.service'
  import { mapState } from "vuex"

  export default {
    // computed: {
    //   sidebarMini () {
    //     return this.$store.getters.getSidebarMini
    //   }
    // },
    // created() {
    //   this.displaySidebar = SettingsService.getSidebarSettings()
    //   if (this.displaySidebar == true) {
    //     this.$sidebar.toggleMinimize()
    //   }
    // },
    // watch: {
    //   sidebarMini() {
    //       this.$sidebar.toggleMinimize()
    //   }
    // },
    mounted() {
      // window.addEventListener('resize', this.handleResize)
      // this.handleResize()
    },
    components: {
      // TopNavbar,
      // ContentFooter,
      DashboardContent,
      // UserMenu,
    },
    methods: {
      // handleResize() {
        // let navbarHeight = document.getElementById("navbar").offsetHeight
        // let contentHeight = window.offsetHeight - 0 //navbarHeight
        // document.getElementById("content").style.height = contentHeight + 'px'
        // document.getElementById("content").style.marginTop = 80 + 'px'
      // },
    //   toggleSidebar () {
    //     if (this.$sidebar.showSidebar) {
    //       this.$sidebar.displaySidebar(false)
    //     }
    //   }
    }
  }
</script>

<style>
content {
  height: 100v;
}
</style>
