<template>
	<div>
		<div id="content-wrapper-mf-map">
      <map-side-bar></map-side-bar>
			<main-map></main-map>
		</div>
		<map-foot-bar id="map-footer" class="mf-map-foot-bar"></map-foot-bar>
	</div>
</template>

<script>

import MapSideBar from './HomeBlock/MapSideBar'
import MapFootBar from './HomeBlock/MapFootBar'
import MainMap from './HomeBlock/MainMap'
import moduleMenu from "./HomeBlock/ModuleMenu";

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import { store } from "../../../../ingeo-mf-styleguide/src/store_";
/// #else
import { store } from "@ingeo-mf/styleguide";
/// #endif

export default {
	components: {
		MainMap,
    MapSideBar,
		MapFootBar
	},
	data() {
		return {
			contentHeight: Number
		}
	},
	mounted() {

		window.addEventListener('resize', this.handleResize)
		this.handleResize();
  },
  created() {
    this.setMenuData(moduleMenu.menuItems);
    this.setModuleName(moduleMenu.moduleName);
  },
	destroyed() {
		let windowHeight = window.innerHeight
		// document.getElementById("sidebar").style.height = windowHeight + 'px'
		window.removeEventListener('resize', this.handleResize)
	},
	methods: {
    setMenuData: store.mutations.setMenuItems,
    setModuleName: store.mutations.setModuleName,
		handleResize() {
			// let navbarHeight = document.getElementById("navbar").offsetHeight
			// let footerHeight = document.getElementById("footer").offsetHeight
			// let sidebarWidth = document.getElementById("sidebar").offsetWidth

			let windowHeight = window.innerHeight
			let windowWidth = window.innerWidth

			// this.contentHeight = windowHeight - navbarHeight - footerHeight
			// let sidebarHeight = windowHeight - footerHeight
            // this.footerWidth = windowWidth

			this.contentHeight = windowHeight - 0
            document.getElementById("content-wrapper-mf-map").style.height = this.contentHeight + 'px'

			// document.getElementById("footer").style.width = this.footerWidth + 'px'
			// document.getElementById("sidebar").style.height = sidebarHeight + 'px'
		}
  	}
}

</script>

<style scoped>
	#content-wrapper-mf-map {
		position: relative;
	}
	.mf-map-foot-bar {
		position: fixed;
    height: 155px;
    width: 100%;
		clear: both;
		left: 0;
		bottom: 0;
		background: lightgray;
		border-top: 2px solid;
    border-color: #bebebe;
    /* box-shadow: 0 1px 4px rgba(0,0,0,.06), 0 3px 6px rgba(0,0,0,.26); */
	}

</style>
