<template>
  <div>
    <div id="mySidebar" class="mySidebar">

      <!-- <div class="mf-map-bar-header">
        <h1 class="mf-map-bar-header-title">
          <div class="maps-icon-logo"></div>
          <span class="mf-map-bar-header-text">Recorridos</span>
        </h1>
      </div> -->

      <div class="map-mf-bar mf-map-bar-header-title">
        <fg-input class="mf-map-header-input" v-if="this.vehiculosDisponibles">
          <multiselect
            v-model="multiSelectValue"
            :options="vehiculos"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="true"
            group-values="grupoVeh"
            group-label="name"
            :group-select="false"
            placeholder="Seleccionar un vehículo"
            deselectGroupLabel="Deseleccionar el Grupo"
            deselectLabel="Deseleccionar el vehículo"
            selectedLabel="Seleccionado"
            selectGroupLabel="Seleccionar el Grupo"
            selectLabel="Seleccionar el vehículo"
            track-by="pat"
            label="pat"
            :max-height="300"
            @input="onChangeSelectedVehicule"
          >
            <span slot="noResult">No se encontró ningún vehículo..</span>
          </multiselect>
        </fg-input>
      </div>

      <div class="map-mf-histogram-wrapper" v-show="this.patenteSelected">
          <highcharts id="speedChart" ref="high" :options="chartOptions"></highcharts>
      </div>
      <div class="map-mf-slider-wrapper" v-show="this.patenteSelected">
        <slider style="width: 75%; margin-left: 30px;"></slider>
        <button type="button" title="Acercar" @click="zoomToSelectedVeh()"
          class="btn btn-outline-neutral mf-map-pan-to mf-map-pan-to-icon">
        </button>
      </div>

    </div>
    <div @click="openNav()" class="buttonSidebar" id="buttonSidebar">
      <span class="sidebar-mini-icon arrow">
        <i v-if="this.isCollapsed" class="nc-icon nc-minimal-right"></i>
        <i v-else class="nc-icon nc-minimal-left"></i>
      </span>
    </div>
  </div>
</template>


<script>

/// #if process.env.VUE_APP_DEVMODE === 'standalone'
import {
  // Collapse,
  FgInput,
} from "../../../../../ingeo-mf-styleguide/src/UIComponents"
import vehiculosService from "../../../../../ingeo-mf-styleguide/src/services/vehiculos"
/// #else
import {
  FgInput,
} from "@ingeo-mf/styleguide";
import { vehiculosService } from "@ingeo-mf/styleguide";
/// #endif

import Multiselect from "vue-multiselect";
import Collapse from '@/components/Collapse/Collapse'
import CollapseItem from '@/components/Collapse/CollapseItem'
import Slider from '@/components/Slider/Index';

import { mapState } from "vuex";
import {EventBus} from '@/eventBus'
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import Highcharts from "highcharts";

Vue.use(Vue2TouchEvents)

export default {

  components: {
    FgInput,
    Slider,
    Multiselect
  },

  inject: {
    activate: {
      default: () => {}
    }
  },

  data() {
    return {
      vehiculosDisponibles: false,
      vehiculos: [],
      multiSelectValue:'',
      search: "",
      route: [],
      isCollapsed: false,
      grupoActivo: ''
    };
  },

  computed: {
    ...mapState(["patenteSelected", "positionQuery","grupos","map", "routeChart", "marker", "positionLastInfo"]),
    chartOptions(e) {
      return {
        time: {
          timezoneOffset: 3 * 60
        },
        chart: {
          height: "160px",
          // type: 'area'
          backgroundColor: null,
          borderWidth: 0,
          borderRadius: 0,
          plotBackgroundColor: null,
          plotShadow: false,
          plotBorderWidth: 0,
          margin: [0, 20, 30, 20],
          zoomType: "x",
          alignTicks: false
        },
        toolbar: {
          itemStyle: {
            margin: "0px 0px 0px 100px"
          }
        },
        legend: {
          borderWidth: 0,
          align: "left",
          verticalAlign: "top",
          layout: "vertical",
          x: 0,
          y: -10,
          itemHiddenStyle: {
            color: "#888"
          }
        },
        title: {
          text: null
        },
        subtitle: {
          text: null
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            minute: "%H:%M",
            hour: "%H:%M",
            day: "<b>%e. %b</b>",
            week: "<b>%e. %b</b>",
            month: "<b>%e. %b</b>",
            year: "%Y"
          },
          lineColor: "#99A9B9"
        },
        yAxis: {
          gridLineWidth: 0,
          title: { enabled: false, text: "" },
          labels: { enabled: false },
          maxPadding: 0.15,
          min: 0,
          minRange: 120
        },
        tooltip: {
          formatter() {
            var pointIndex = this.series.data.indexOf(this.point);
            // pointIndex = ( this.routeChart.length - 1 ) - pointIndex;

            var months_arr = [
              "En",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Oct",
              "Nov",
              "Dic"
            ];

            // Convert timestamp to milliseconds
            var date = new Date(this.point.options.x);
            var year = date.getFullYear();
            var month = months_arr[date.getMonth()];
            var day = date.getDate();
            var weekday = [
              "Dom,",
              "Lun,",
              "Mar,",
              "Mie,",
              "Jue,",
              "Vie,",
              "Sab,"
            ];
            var hours = date.getHours();
            var minutes = "0" + date.getMinutes();

            var seconds = "0" + date.getSeconds();

            // Display date time in MM-dd-yyyy h:m:s format
            var convdataTime =
              weekday[date.getDay()] +
              " " +
              day +
              ". " +
              month +
              ", " +
              year +
              " - " +
              hours +
              ":" +
              minutes.substr(-2) +
              ":" +
              seconds.substr(-2);

            var timeStr = pointIndex ? "<br/>" + convdataTime : "";

            e.syncMapToChartHover(
              this.point.options.lat,
              this.point.options.lng
            );

            // var yValStr;
            // yValStr = Highcharts.numberFormat(this.y, 0);

            return (
              "<b>Velocidad : " + this.point.y + " Km/h</b><br/>" + timeStr
            );
          }
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 4,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            lineWidth: 3,
            events: {
              //TODO
              mouseOut: function(event) {
                e.syncMapChartMouseOut();
                // if (this.marker) {
                //   this.marker.setMap(null);
                // }
              }
              // mouseOut: this.syncMapChartMouseOut()
            }
          }
        },
        credits: {
          enabled: false
        },
        series: [
          {
            showInLegend: false,
            type: "area",
            name: "Velocidad",
            data: this.routeChart
          }
        ]
      };
    }
  },

  created() {
    this.getVehiculos();
  },
  watch: {
    patenteSelected() {
      this.setMultiSelect(this.patenteSelected)
    },
    routeChart() {
      this.chartOptions.series[0].data = this.routeChart;
      this.findMaxSpeed()
    },
  },

  methods: {
    findMaxSpeed(){
        var puntosVelMax = []
        let velMax = this.positionLastInfo.vmax

        for (let i = 0; i < this.routeChart.length; i++) {
          if (this.routeChart[i].y == velMax) {
            let puntoMax = {
              indice: i,
              obj: this.routeChart[i]
            }
            puntosVelMax.push(puntoMax)
          }
        }

        for (let i = 0; i < puntosVelMax.length; i++) {
            this.chartOptions.series[0].data[puntosVelMax[i].indice].marker = {
              enabled: true,
              symbol: "diamond",
              fillColor: '#FF0000',
              lineWidth: 2,
              radius: 6,
              states: {
                hover: {
                    enabled: true,
                    fillColor: '#FF0000'
                }
              }
            }
        }

    },
    syncMapToChartHover(lat, lng) {
      if (this.map) {
        var logLatlng = new google.maps.LatLng(lat, lng);
        this.marker.setPosition(logLatlng);
        this.marker.setMap(this.map);
      }
    },
    syncMapChartMouseOut() {
      if (this.marker) {
        this.marker.setMap(null);
      }
    },
    onChangeSelectedVehicule(item) {
      if (item) {
        this.selectPatente(item.pat);
      }
      else {
        this.selectPatente('');
      }
    },
    zoomToSelectedVeh() {
      this.mapPanToPin(this.patenteSelected);
    },
    selectPatente(pat) {
      var self = this
      var width = window.innerWidth
      if (width > 768) {
        self.$store.commit("selectPatente", {
          patenteVehiculo: pat
        });
      } else {
        self.$store.dispatch("selectPatente", {
          patenteVehiculo: pat
        }).then(
          self.mapPanToPin(pat),
          this.openNav()
        )
      }
    },
    mapPanToPin(vehId) {
      EventBus.$emit('map-pan-to-pin', {'vehId': vehId})
    },
    getVehiculos() {
      vehiculosService.index().then(res => {
        this.$store.commit("setGrupos", { gruposVehiculos: res.data });
        this.formatMultiselectData(res.data);
        this.vehiculosDisponibles = true;
        if(this.patenteSelected){
          this.setMultiSelect(this.patenteSelected)
        }
      });
    },
    setMultiSelect (vehId) {
      if (!vehId) return;
      this.multiSelectValue  = this.vehiculos.find(grupo => grupo.grupoVeh
                                             .find( veh => veh.pat === vehId))
                                             .grupoVeh.find( veh => veh.pat === vehId);
    },
    formatMultiselectData(gruposVehiculos) {
      var i;
      var j;
      for (i = 0; i < gruposVehiculos.length; i++) {
        var grupo = {
          name: gruposVehiculos[i].grupo_nombre,
          grupoVeh: []
        };
        for (j = 0; j < gruposVehiculos[i].grupos_vehiculos.length; j++) {
          var veHSel = {
            pat: gruposVehiculos[i].grupos_vehiculos[j].veh_id,
            key: gruposVehiculos[i].grupos_vehiculos[j].veh_id
          };
          grupo.grupoVeh.push(veHSel);
        }
        this.vehiculos.push(grupo);
      }
    },

    filterVehicles(item) {
      return item.grupos_vehiculos.filter(vehiculo => {
        return vehiculo.veh_id.match(this.search.toUpperCase());
      });
    },
    openNav() {
      if (this.isCollapsed == true) {
        var self = this;
        setTimeout(function() {
          self.isCollapsed = false;
        }, 400);
        document.getElementById("mySidebar").classList.remove("expandSidebar");
        document
          .getElementById("buttonSidebar")
          .classList.remove("expandButtonSidebar");
      } else {
        document.getElementById("mySidebar").classList.add("expandSidebar");
        document
          .getElementById("buttonSidebar")
          .classList.add("expandButtonSidebar");
        this.isCollapsed = true;
      }
    }
  },

};

</script>

<style>
.mf-map-bar-header-text {
  margin: 0 0 0 15px;
}

.map-mf-bar {
    background-color: lightgray;
    border-bottom: #e0e0e0 1px solid;
    border-bottom-right-radius: 2px;
    box-sizing: border-box;
    color: rgba(0,0,0,.65);
    font-size: 13px;
    font-weight: 400;
    height: 60px;
    padding: 15px 20px 0px 20px;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    box-shadow: 0 1px 4px rgba(0,0,0,.06), 0 3px 6px rgba(0,0,0,.26);
    /* overflow: visible !important; */

}

.map-mf-histogram-wrapper {
    background-color: #fafafa;
    border-bottom-right-radius: 2px;
    height: 160px;
    overflow: hidden;
    position: relative;
    width: 533px;
}

.map-mf-slider-wrapper {
    background-color: #fafafa;
    border-bottom-right-radius: 2px;
    height: 75px;
    padding-top: 35px;
    overflow: hidden;
    position: relative;
    width: 533px;
    display: flex;
}
.mf-map-header-input {
    width: 100%;
}
.mf-map-bar-header {
    background-color: #fff;
    height: 36px;
    padding: 0 24px;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}


.mf-map-bar-header-title {
    margin-top: -10px;
    /* margin-block-start: 0.67em; */
    /* margin-block-end: 0.67em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: rgba(0,0,0,.65);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-transition: color .218s cubic-bezier(0.4,0.0,0.2,1);
    -moz-transition: color .218s cubic-bezier(0.4,0.0,0.2,1);
    -o-transition: color .218s cubic-bezier(0.4,0.0,0.2,1);
    transition: color .218s cubic-bezier(0.4,0.0,0.2,1);
}
.maps-icon-logo {
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    background-size:contain;
    background-image: url('../../../assets/img/icons/icon-map-stop-32.png');
}
.mf-map-pan-to {
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: 35px;
}
.btn:hover{
  border-color: #bebebe  !important;
  border-width: 2px !important;
}

.mf-map-pan-to-icon {
    background-repeat: no-repeat;
    /* height: 100%; */
    background-size:contain;
    background-image: url('../../../assets/img/icons/icon-map-route.png');
}
.patente_val {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently */
}

.buttonSidebar {
  position: absolute;
  height: 60px;
  background-color: lightgray;
  width: 60px;
  left: 523px;
  z-index: 1;
  /* transition: all 0.5s ease; */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: #bebebe;
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important;
  /* margin-left: -10px; */
  margin-top: 61px;
  background-size: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../../assets/img/icons/icon-select2.png');

}

/* @media all and (max-width: 1650px) { */
@media all and (max-width: 1024px) {
  .buttonSidebar {
    left: 250px;
  }
  .mySidebar {
    width: 250px !important;
  }
}

.expandSidebar {
  /* width: 0px !important;
  min-width: 0px !important; */
  /* border-right: 0 solid !important;
  overflow: overlay !important; */
  display: none;
}


.buttonSidebar:hover {
  background-color: #868483;
}

.buttonSidebar:hover .arrow {
  color: #272727;
}

.expandButtonSidebar {
  left: 0%;
  border-left: none;
  /* transition: all 0.5s ease; */
}

.mySidebar {
  /* overflow: overlay; */
  overflow: visible;
  /* height: 320px; */
  /* height: calc(100% - 63px); */
  /* height: calc(100% - 155px - 63px); */

  width: 533px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: -10px;
  background-color: white;
  /* transition: 0.5s; */
  padding-top: 10px;
  border-color: #000000;
  margin-top: 60px;
  box-shadow: 0 1px 4px rgba(0,0,0,.06), 0 3px 6px rgba(0,0,0,.26);

}

.mySidebar a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  font-weight: bolder;
  display: block;
  /* transition: 0.3s; */
}

.mySidebar::-webkit-scrollbar {
  display: none;
}

.mySidebar:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: block;
}


.mySidebar::-webkit-scrollbar-thumb {
    background: #ccc;
}

.mySidebar::-webkit-scrollbar-thumb:hover {

    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.mySidebar::-webkit-scrollbar-thumb:active {
    border-radius: 10px;
}

</style>
